import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReviewCard from './ReviewCard';
import { fetchReviews } from '../redux/reviewsSlice';
import './ReviewCarousel.css';

const ReviewCarousel = () => {
  const dispatch = useDispatch();
  const carouselRef = useRef(null);
  const { reviews, status } = useSelector((state) => state.reviews);
  const intervalRef = useRef(null); // To store the interval reference

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchReviews());
    }
  }, [dispatch, status]);

  let content;

  if (status === 'loading') {
    content = <div>Loading...</div>;
  } else if (status === 'failed') {
    content = <div>Error fetching reviews.</div>;
  } else if (reviews.length === 0) {
    content = <div>No reviews available.</div>;
  } else {
    content = reviews.map((review) => (
      <ReviewCard key={review.id} quote={review.content} author={review.name} />
    ));
  }

  // Function to scroll the carousel
  const scrollCarousel = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: carouselRef.current.offsetWidth, // Scroll by the width of the carousel
        behavior: 'smooth', // Smooth scrolling
      });
    }
  };

  useEffect(() => {
    intervalRef.current = setInterval(scrollCarousel, 3000); // Auto-scroll every 3 seconds

    return () => clearInterval(intervalRef.current); // Cleanup interval on unmount
  }, []);

  // Handle mouse enter and leave
  const handleMouseEnter = () => {
    clearInterval(intervalRef.current); // Stop auto-scroll on hover
  };

  const handleMouseLeave = () => {
    intervalRef.current = setInterval(scrollCarousel, 3000); // Resume auto-scroll
  };

  // Scroll functions for buttons
  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -carouselRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: carouselRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div
      className="carousel-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button className="carousel-button left" onClick={scrollLeft}>
        &#9664;
      </button>
      <div className="carousel" ref={carouselRef}>
        {content}
      </div>
      <button className="carousel-button right" onClick={scrollRight}>
        &#9654;
      </button>
    </div>
  );
};

export default ReviewCarousel;
