import { createSlice } from '@reduxjs/toolkit';
import { addReviewToFirestore, fetchReviewsFromFirestore } from '../firebase';

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState: {
    reviews: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    addReview: (state, action) => {
      state.reviews.push(action.payload);
    },
    deleteReview: (state, action) => {
      state.reviews = state.reviews.filter(review => review.id !== action.payload);
    },
    setReviews: (state, action) => {
      state.reviews = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { addReview, deleteReview, setReviews, setStatus, setError } = reviewsSlice.actions;

export default reviewsSlice.reducer;

// Thunks
export const fetchReviews = () => async dispatch => {
  dispatch(setStatus('loading'));
  try {
    const reviews = await fetchReviewsFromFirestore();
    dispatch(setReviews(reviews));
    dispatch(setStatus('succeeded'));
  } catch (error) {
    dispatch(setError(error.toString()));
    dispatch(setStatus('failed'));
  }
};

export const addReviewToStore = (review) => async dispatch => {
  try {
    await addReviewToFirestore(review);
    dispatch(addReview(review));
  } catch (error) {
    dispatch(setError(error.toString()));
  }
};
