import React from 'react';
import { useParams } from 'react-router-dom';
import { courses } from './CourseData'; // Adjust the path as needed
import './CourseDetail.css';
import ScrollEffect1 from './ScrollEffect1';

const CourseDetail = () => {
  const { id } = useParams();
  const course = courses.find(course => course.id === parseInt(id));

  if (!course) {
    return <div>Course not found</div>;
  }

  return (
    <ScrollEffect1  animateIn="fade-in">
      <div className="course-detail">
     
     <h1>{course.title}</h1>
     <p className='paragraph'>{course.para}</p>
     <div 
       className="course-description" 
       dangerouslySetInnerHTML={{ __html: course.description }}
     />
   </div>
    </ScrollEffect1>
  );
};

export default CourseDetail;
