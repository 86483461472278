import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReviews } from '../redux/reviewsSlice';

import { useAuth } from '../context/AuthContext';
import { updateReviewDisplay, deleteReviewFromFirestore } from '../firebase';

const AdminReviewManagement = () => {
  const dispatch = useDispatch();
  const { reviews, status } = useSelector((state) => state.reviews);
  const { user } = useAuth(); // Assuming useAuth provides the logged-in user

  useEffect(() => {
    dispatch(fetchReviews());
  }, [dispatch]);

  const handleApprove = (reviewId) => {
    // Logic to approve and display review
    updateReviewDisplay(reviewId, true); // Update Firestore
    dispatch(fetchReviews()); // Refresh reviews
  };

  const handleDelete = (reviewId) => {
    // Logic to delete review
    dispatch(deleteReviewFromFirestore(reviewId));
    // Also remove from Firestore
    deleteReviewFromFirestore(reviewId);
  };

  if (!user || !user.isAdmin) {
    return <div>Access Denied</div>;
  }

  return (
    <div>
      <h2>Admin Review Management</h2>
      {status === 'loading' && <p>Loading...</p>}
      {status === 'failed' && <p>Error fetching reviews.</p>}
      {reviews.length === 0 ? (
        <p>No reviews to manage.</p>
      ) : (
        <ul>
          {reviews.map(review => (
            <li key={review.id}>
              <p>{review.content}</p>
              <button onClick={() => handleApprove(review.id)}>Approve</button>
              <button onClick={() => handleDelete(review.id)}>Delete</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AdminReviewManagement;
