export const courses = [
    { 
      id: 2,
      title: 'Kids Vocal Music',
      para: 'The Voicenotes Academy presents KIDS VOCAL MUSIC COURSE which typically focuses on teaching children the fundamentals of singing in a fun and engaging way. Here’s an outline of the course:', 
      description: `
        <p><strong>1. Warm-ups and Vocal Exercises</strong></p>
        <ul>
          <li><strong>Breathing Techniques</strong>: Learning proper breathing for singing.</li>
          <li><strong>Vocal Warm-ups</strong>: Simple scales, humming, Aum, vowels, and different sound practices to get the voice ready.</li>
          <li><strong>Posture</strong>: Teaching the importance of good posture for singing.</li>
        </ul>
        <p><strong>2. Pitch and Rhythm Training</strong></p>
        <ul>
          <li><strong>Pitch Matching</strong>: Exercises to help children identify and replicate pitches.</li>
          <li><strong>Rhythm Games</strong>: Fun activities to help understand and keep time with music.</li>
        </ul>
        <p><strong>3. Basic Music Theory</strong></p>
        <ul>
          <li><strong>Introduction to Notes</strong>: Learning about different notes and how to read them.</li>
          <li><strong>Understanding Beats</strong>: Simple concepts of beats, measures, and tempo.</li>
        </ul>
        <p><strong>4. Song Learning</strong></p>
        <ul>
          <li><strong>Age-Appropriate Songs</strong>: Learning simple, fun songs that are suitable for their age and vocal range with sargam.</li>
          <li><strong>Solo Practice</strong>: Opportunities to sing individually to build confidence.</li>
        </ul>
        <p><strong>5. Performance Skills</strong></p>
        <ul>
          <li><strong>Stage Presence</strong>: Teaching kids how to stand, hold the microphone, and engage with the audience.</li>
          <li><strong>Expressing Emotion</strong>: Learning how to convey the emotion of the song through facial expressions and gestures.</li>
        </ul>
        <p><strong>6. Introduction of Basic Indian Raagas</strong></p>
        <p><strong>7. Feedback and Encouragement</strong></p>
        <ul>
          <li><strong>Positive Reinforcement</strong>: Encouraging children with positive feedback to boost their confidence.</li>
          <li><strong>Peer Feedback</strong>: Teaching kids how to give and receive constructive feedback.</li>
        </ul>
        <p>This type of course is designed to be both educational and enjoyable, helping children develop a love for music while building their vocal skills.</p>
      `
    },
    { 
      id: 3,
      title: 'Devotional Vocal Music',
      para: 'Explore devotional music techniques and styles.',
      description: `
        <p><strong>1. Introduction to Indian Devotional Music</strong></p>
        <ul>
          <li><strong>Overview</strong>: Understanding the significance of devotional music in Indian culture and spirituality.</li>
          <li><strong>Types of Devotional Music</strong>: Exploring different forms like Bhajans, Kirtans, Shabads, and Aartis.</li>
        </ul>
        <p><strong>2. Basic Vocal Training</strong></p>
        <ul>
          <li><strong>Breathing Exercises</strong>: Techniques to improve breath control for singing.</li>
          <li><strong>Vocal Warm-ups</strong>: Simple exercises to prepare the voice for singing.</li>
          <li><strong>Voice Modulation</strong>: Learning how to control and modulate the voice for expressive singing.</li>
        </ul>
        <p><strong>3. Learning Raagas</strong></p>
        <ul>
          <li><strong>Introduction to Raagas</strong>: Basic understanding of raagas used in devotional music.</li>
          <li><strong>Practice of Simple Raagas</strong>: Learning and practicing raagas like Bhairav, Yaman, and Durga, which are commonly used in devotional songs.</li>
        </ul>
        <p><strong>4. Bhajan/Kirtan Singing</strong></p>
        <ul>
          <li><strong>Understanding Lyrics</strong>: Delving into the meaning and spiritual significance of the lyrics.</li>
          <li><strong>Learning Popular Bhajans/Kirtans</strong>: Practicing well-known devotional songs and Aartis.</li>
        </ul>
        <p><strong>5. Rhythm and Tala</strong></p>
        <ul>
          <li><strong>Introduction to Tala</strong>: Basic understanding of rhythmic cycles like Dadra, Keherwa, and Bhajani.</li>
          <li><strong>Clapping and Counting</strong>: Learning to keep rhythm using claps and hand movements.</li>
        </ul>
        <p><strong>6. Expression and Bhava (Emotion)</strong></p>
        <ul>
          <li><strong>Expressing Devotion</strong>: Techniques to convey devotion and emotion through music.</li>
          <li><strong>Connecting with the Divine</strong>: Understanding how to use music as a medium for spiritual connection.</li>
        </ul>
        <p><strong>7. Performance Practice</strong></p>
        <ul>
          <li><strong>Stage Presence</strong>: Guidance on how to perform devotional music in front of an audience with grace and reverence.</li>
        </ul>
        <p>This course is designed to immerse students in the spiritual practice of Indian devotional music, fostering both musical skills and a deeper connection to the divine.</p>
      `
    },
    { 
      id: 1, 
      title: 'The Complete Vocal Music',
      para: 'The Voicenotes Music Academy presents an Indian Vocal Music course which is tailored to suit both beginners and those with some prior musical experience, focusing on the rich traditions of Indian classical music. Here\'s what this course includes:',
      description: `
        <p><strong>1. Introduction to Indian Classical Music</strong></p>
        <ul>
          <li><strong>Overview</strong>: Understanding the basics of Hindustani music traditions.</li>
          <li><strong>Key Concepts</strong>: Introduction to Swara (notes), Raaga (melodic framework), and Tala (rhythmic cycle).</li>
        </ul>
        <p><strong>2. Voice Training and Warm-ups</strong></p>
        <ul>
          <li><strong>Body Warm Ups</strong>: Exercises to prepare the body for singing.</li>
          <li><strong>Breathing Techniques</strong>: Exercises to develop breath control and support for singing.</li>
          <li><strong>Vocal Warm-ups</strong>: Basic exercises like Sa-Re-Ga-Ma (solfège) and alankars (patterns) to build vocal strength and flexibility.</li>
          <li><strong>Vowels and Different Sound Practices</strong>: Practicing different vowel sounds and techniques.</li>
          <li><strong>Pitching and Scales</strong>: Practicing pitch accuracy and learning to sing scales in different octaves.</li>
        </ul>
        <p><strong>3. Learning Raagas</strong></p>
        <ul>
          <li><strong>Basic Raagas</strong>: Introduction to foundational raagas like Yaman, Bhupali, and Bhairav.</li>
          <li><strong>Arohana and Avarohana</strong>: Understanding and practicing the ascending (Arohana) and descending (Avarohana) scales of raagas.</li>
          <li><strong>Bandish/Composition</strong>: Learning simple compositions (bandish) in each raaga, including Vilambit (slow) and Drut (fast) tempos.</li>
        </ul>
        <p><strong>4. Tala and Rhythm</strong></p>
        <ul>
          <li><strong>Understanding Tala</strong>: Introduction to popular talas like Teentaal, Jhaptaal, and Roopak.</li>
          <li><strong>Clapping and Counting</strong>: Practicing the cycles of talas through clapping and counting exercises.</li>
          <li><strong>Laya (Tempo) Practice</strong>: Learning to maintain and control tempo while singing.</li>
        </ul>
        <p><strong>5. Voice Modulation and Expression</strong></p>
        <ul>
          <li><strong>Voice Dynamics</strong>: Techniques to control volume, tone, and expression in singing.</li>
          <li><strong>Emotional Expression</strong>: Learning how to convey emotions and bhava (mood) through music.</li>
        </ul>
        <p><strong>6. Improvisation (Alaap, Taan, Sargam)</strong></p>
        <ul>
          <li><strong>Alaap</strong>: Learning how to improvise in a raaga with slow, non-metrical phrases.</li>
          <li><strong>Taan</strong>: Practicing fast, intricate patterns within a composition.</li>
          <li><strong>Sargam</strong>: Singing note patterns (Sa Re Ga Ma) as part of improvisation.</li>
        </ul>
        <p><strong>7. Song Repertoire Development</strong></p>
        <ul>
          <li><strong>Expanding Repertoire</strong>: Learning a variety of songs, including classical compositions, light music, and regional folk songs.</li>
        </ul>
        <p><strong>8. Performance Skills</strong></p>
        <ul>
          <li><strong>Stage Presence</strong>: Guidance on performing confidently in front of an audience.</li>
          <li><strong>Microphone Technique</strong>: Tips for using a microphone effectively during performances.</li>
        </ul>
        <p><strong>Additional Features</strong></p>
        <ul>
          <li><strong>Recording Sessions</strong>: Opportunities to record and review your singing for self-assessment.</li>
          <li><strong>Personalized Coaching</strong>: One-on-one sessions to address individual strengths and areas for improvement.</li>
          <li><strong>Theory Lessons</strong>: Basic music theory, including notation, to complement practical training.</li>
        </ul>
        <p>This course aims to provide students with a comprehensive foundation in Indian vocal music, nurturing both technical skills and a deep appreciation for the art form.</p>
      `
    }
  ];
  