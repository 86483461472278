import React, { useState } from 'react';

import CourseItem from '../components/CourseItem';
import kid from '../assests/headphones-solid.svg';
import flip2 from '../assests/music-solid.svg';
import flip3 from '../assests/microphone-solid.svg';
import './Courses.css';
import ScrollEffect1 from '../components/ScrollEffect1';

const courses = [
  { id: 1, title: 'The Complete Indian Vocal Music', image: flip3 },
  { id: 2, title: 'Kids Vocal Music', image: kid },
  { id: 3, title: 'Devotional Vocal Music', image: flip2 }
];

const Courses = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);

  return (
    
      <div className='cource'>
       
        <div className="course-list">
          {courses.map(course => (
            <CourseItem 
              key={course.id} 
              course={course} 
              onClick={() => setSelectedCourse(course)} 
            />
          ))}
        </div>
    </div>
    
  );
}

export default Courses;
