import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDVhjtY3vEVFS-eNQbtFltrJkmEoMUWPpg",
  authDomain: "voice-notes-259cf.firebaseapp.com",
  projectId: "voice-notes-259cf",
  storageBucket: "voice-notes-259cf.appspot.com",
  messagingSenderId: "828109950426",
  appId: "1:828109950426:web:7b4da3864d69386b6704e3",
  measurementId: "G-8NQ4G9TKMN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);


// Add review to Firestore
export const addReviewToFirestore = async (review) => {
  try {
    await addDoc(collection(db, 'reviews'), review);
  } catch (error) {
    console.error('Error adding review:', error);
  }
};

// Fetch reviews from Firestore
export const fetchReviewsFromFirestore = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'reviews'));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error fetching reviews:', error);
    return [];
  }
};

// Update review display status
export const updateReviewDisplay = async (reviewId, shouldDisplay) => {
  try {
    const reviewRef = doc(db, 'reviews', reviewId);
    await updateDoc(reviewRef, { display: shouldDisplay });
  } catch (error) {
    console.error('Error updating review display:', error);
  }
};

// Delete review from Firestore
export const deleteReviewFromFirestore = async (reviewId) => {
  try {
    const reviewRef = doc(db, 'reviews', reviewId);
    await deleteDoc(reviewRef);
  } catch (error) {
    console.error('Error deleting review:', error);
  }
};
