import React from 'react';
import AboutSection from '../components/AboutSection';
import AboutImage1 from '../assests/Profile2.jpeg'; 
import AboutImage2 from '../assests/profile.jpg'; 
import ScrollEffect1 from '../components/ScrollEffect1';

function About() {
  return (
    <div className="about">
      <div className='head'> 
        <h2>About Us</h2>
      </div>
      <ScrollEffect1  animateIn="fade-left">
      <AboutSection
        imageSrc={AboutImage1}
        title="Founder"
        description="Gayatri Lavesh Vani is the visionary founder of 'The Voicenotes Music Academy', established in memory of her late husband, Mr. Lavesh Vani, who was a renowned singer and performer. With over 35 years of experience as a government teacher, she currently serves as the Head of a government school. Mr. Lavesh Vani dedicated his entire life to music, a passion that continues in their daughter, Mrs. Priyani Vani Panditt, and Son Mr. Geet Vani, who are both professional singers, performers, and music enthusiasts. Driven by her belief in the profound impact of music on human life, Gayatri ji founded the academy to nurture and inspire the next generation of musicians."
        reverse={false}  // Image on the left, text on the right
      />
      </ScrollEffect1>
      <ScrollEffect1  animateIn="fade-right">
      <AboutSection
        imageSrc={AboutImage2}
        title="Co-Founder"
        description="Welcome to The Voicenotes Music Academy, founded by Priyani Vani Panditt, a distinguished figure in the Indian music industry with over 20 years of experience as a playback singer, performer, and vocal coach. At our academy, we are dedicated to providing top-notch music education and training to aspiring singers. Through our comprehensive curriculum, expert instructors, and innovative teaching methods, we strive to cultivate a vibrant community of singers who are equipped with the knowledge, skills, and confidence to excel in their musical endeavors. Whether you're a beginner taking your first steps in music or an advanced musician honing your craft, we are committed to helping you reach your full potential. We extend a special thanks to Mrs. Neha Rathore, a renowned Piano teacher and performer based in Cupertino (San Francisco), who has been instrumental in shaping Priyani's journey as a teacher and mentor. Neha's guidance and support have been invaluable in the establishment of this academy, and we are grateful for her continued inspiration and collaboration. Together, we strive to create a nurturing environment where students can develop their musical talents and reach their fullest potential. Join us at The Voicenotes Music Academy and embark on a transformative musical journey today."
        reverse={true}   // Image on the right, text on the left
      />
      </ScrollEffect1>
    </div>
  );
}

export default About;
