import React from 'react';
import { useSelector } from 'react-redux';
import './Review.css'; 
import { Link } from 'react-router-dom';
import ReviewCarousel from './ReviewCarousel';

const Review = () => {
  const reviews = useSelector((state) => state.reviews);

  return (
    <>
      <h1>What Our Students Say About Us</h1>
      <ReviewCarousel quotes={reviews} />
      
      <Link to='/submit-review' className='review-btn'>
        <button>Submit Your Review</button>
      </Link>
    </>
  );
};

export default Review;
