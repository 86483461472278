import React, { useEffect, useState } from 'react';
import ReviewCard from './ReviewCard';
import { db } from '../firebase'; // Firebase initialization
import './AllReview.css';

const AllReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const snapshot = await db.collection('reviews').get();
        const fetchedReviews = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setReviews(fetchedReviews);
      } catch (error) {
        console.error("Error fetching reviews: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  if (loading) return <div>Loading reviews...</div>;

  return (
    <div className="all-reviews-container">
      <h1>All Reviews</h1>
      <div className="review-list">
        {reviews.map((review) => (
          <ReviewCard key={review.id} quote={review.content} author={review.name} />
        ))}
      </div>
    </div>
  );
};

export default AllReviews;
