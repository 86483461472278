import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addReview } from '../redux/reviewsSlice';
import { v4 as uuid } from 'uuid'; // Import uuid to generate unique IDs
import { db } from "../firebase"; // Import your Firestore instance
import { collection, addDoc } from "firebase/firestore"; // Firestore methods
import './ReviewForm.css'; // Import your CSS file

const ReviewForm = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    content: '',
  });
  const [submissionSuccess, setSubmissionSuccess] = useState(false); // State to track submission success

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create the new review object with all required fields
    const newReview = {
      id: uuid(), // Unique identifier for each review
      name: formData.name,
      email: formData.email,
      content: formData.content,
      timestamp: new Date().toISOString(), // Store the time of submission
    };

    // Dispatch the action to add the review to the Redux store
    dispatch(addReview(newReview));

    try {
      // Save the review to Firestore
      await addDoc(collection(db, "reviews"), newReview);
      console.log("Review added successfully to Firestore");
      setSubmissionSuccess(true); // Set success message
    } catch (error) {
      console.error("Error adding review to Firestore:", error);
    }

    // Optionally, clear the form after submission
    setFormData({ name: '', email: '', content: '' });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className='Form1'>
      <div className="review-form-container">
        <h2>Submit Your Review</h2>
        {submissionSuccess && (
          <div className="success-message">Review submitted successfully!</div>
        )}
        <form onSubmit={handleSubmit} className="review-form">
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your Name"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="review">Review:</label>
            <textarea
              id="review"
              name="content"
              value={formData.content}
              onChange={handleChange}
              placeholder="Your Review"
              required
            />
          </div>
          <button type="submit" className="submit-button">Submit Review</button>
        </form>
      </div>
    </div>
  );
};

export default ReviewForm;
