import React from 'react';
import './Footer.css';
import logo from '../assests/logo.png';
import { NavLink } from 'react-router-dom';
import ScrollEffect1 from './ScrollEffect1';
const Footer = () => {
  return (
   <ScrollEffect1 animateIn="fade-in">
     <footer className="footer-distributed">
      <div className="footer-left">
      <img src={logo} alt="" />
        <p className="footer-links">
          <NavLink exact to="/" className="link-1">Home</NavLink>
          <NavLink to="/About" className="link-1">About</NavLink>
          <NavLink to="/Courses" className="link-1">Courses</NavLink>
          <NavLink to="/Contact" className="link-1">Contact</NavLink>
        </p>
        <div>
          <i className="fa fa-envelope"></i>
          <p><a href="mailto:support@company.com">Info@thevoicenotes.in</a></p>
        </div>
      </div>

      
      <div className="footer-right">
        <p className="footer-company-about">
          <span>The Voicenotes</span>
          Our institution is dedicated to nurturing musical talent and providing comprehensive education in various aspects of music
        </p>
        <div className="footer-icons">
          <a href="#"><i className="fa fa-facebook"></i></a>
         
          <a href="https://youtube.com/@thevoicenotes?si=Ga8puOMbYcqPE3pT"><i className="fa fa-youtube"></i></a>
        </div>
      </div>
    </footer>
   </ScrollEffect1>
  );
};

export default Footer;
