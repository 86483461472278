import React from 'react';
import '../pages/About.css';

function AboutSection({ imageSrc, title, description, reverse }) {
  return (
    <div className={`aboutsection ${reverse ? 'reverse' : ''}`}>
      <div className="image-banner">
        <img src={imageSrc} alt={title} />
      </div>
      <div className="description-banner">
        <h2 className="title">{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default AboutSection;
