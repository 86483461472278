// src/App.js
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Courses from './pages/Courses';
import Contact from './pages/Contact';
import Navbar from "./components/Navbar";
import Footer from './components/Footer';
import ReviewForm from './components/ReviewForm';
import AllReviews from './components/AllReview';
import AdminManagement from './components/AdminReviewManagement';
import CourseDetail from './components/CourseDetail';
function App() {
  return (
    <>
      <main>
        <Router>
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/About' element={<About />} />
            <Route path='/Courses' element={<Courses />} />
            <Route path="/course/:id" element={<CourseDetail />} />
            <Route path='/Contact' element={<Contact />} />
            <Route path="/submit-review" element={<ReviewForm />} />
            <Route path="/reviews" element={<AllReviews />} />
            <Route path="/admin" element={<AdminManagement />} /> {/* Add route for AdminManagement */}
          </Routes>
          <Footer />
        </Router>
      </main>
    </>
  );
}

export default App;
