import React from 'react';
import './Home.css';
import '../components/ScrollEffect1'
import Cards from '../components/Cards';
import kid from '../assests/kid.jpg';
import flip2 from '../assests/flip2.jpg';
import flip3 from '../assests/flip3.jpg';
import Review from '../components/Review';
import { Link } from 'react-router-dom';
import { Flip } from '@mui/icons-material';
import ScrollEffect1 from '../components/ScrollEffect1';



const Home = () => {
  
  return (
    /* 
    <div className='home-container'>
      <section>
       <div className='title-container'>
       <Title></Title>
       <h3></h3>
      </div>
     </section>
      <div className=''></div>
    </div>
    */
   
 
   
   <>
      <div className='hero'>
        <div className='image'>
          <ScrollEffect1 animateIn="fade-in">
          <h1 className='heading'>THE <br/>VOICENOTES  MUSIC  <br/>ACADEMY</h1>

         </ScrollEffect1>
           
          </div>
     </div>
    
    
      <ScrollEffect1 animateIn="fade-in-section">
      <div className='about-section'>
        
        <div className='ab-right'>
          <h2>About Us</h2>
          <h5>Music is the divine way to tell beautiful, <br/>poetic things to the heart</h5>
          <p>At our academy, we are dedicated to providing top-notch music education and training to aspiring singers. </p>
          <Link to="/About" className="button-link">
            <button>Know More</button>
          </Link>
        </div>
      </div>

         </ScrollEffect1>
      

     
   


     <div className='Our-Programs'>
       <h1>Our Programs</h1>
       <h2>Our class is where your passions come into play every day.</h2>

       <div className='cardss'>
         <Cards 
            img={flip3}
            title="Complete Indian Vocal Music Cource"
            para= {` 
  Vocal warm ups

Breathing exercises

12 notes introduction 

Advanced  surs & alankars

Western Vowels

`
          
            }
         />
      <Cards 
         img={kid}
         title="Kids Vocal Music Cource"
         para={`
   Vocal warm ups 

    Breathing exercises 

   Humming 

   Aum 

   Gayatri Mantra  

  `
         }
       />
      <Cards 
         img={flip2}
         title="Indian Devotional Vocal Music Cource"
         para={`
         
   Vocal warm ups 

   Breathing exercises 

   Humming 

   Aum

   7 surs & alankars

   12 notes introduction`}

       />
      </div>
     

       
      
     <ScrollEffect1 animateIn="fade-in">
     <div className='customer'>
        <Review />
      </div>
     </ScrollEffect1>
    

      
     
    </div>
   </>
  )
}

export default Home;