import React from 'react';

const ReviewCard = ({ quote, author, company }) => {
  return (
    <figure className="snip1533">
      <figcaption>
        <blockquote>
          <p>{quote}</p>
        </blockquote>
        <h3>{author}</h3>
     
      </figcaption>
    </figure>
  );
};

export default ReviewCard;
