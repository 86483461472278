import React from 'react';
import { useNavigate } from 'react-router-dom';

const CourseItem = ({ course }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/course/${course.id}`);
  };

  return (
    <div className="course-item" onClick={handleClick}>
       
      <img src={course.image} alt={course.title} />
      <h3>{course.title}</h3>
    </div>
  );
};

export default CourseItem;
