import React ,{useState}from 'react'
import './Cards.css';
import { Link } from 'react-router-dom';
import ScrollEffect1 from './ScrollEffect1';
const Cards = ({ title, para,img})  => {

 
   
  return (
    <>
  
    <ScrollEffect1 animateIn="fade-down">
    <div className="card" >
          <div className='inner'>
          <div className="front" style={{ backgroundImage: `url(${img})` }}>
          
            <h3 className="sub-title">{title}</h3>
            
          </div>
          <div className="back">
            <p className='parag'>{para}</p>
            <Link to ="/Courses">
            <div className="btn">Get Details</div>
            
            </Link>
          </div>
          </div>
      </div>
    </ScrollEffect1>

    </>
  );
}


export default Cards;