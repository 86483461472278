import React, { useState } from 'react';
import logo from '../assests/logo.png';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='navbar'>
      <div className='nav-bg'>
        <div className='left'>
          <img src={logo} alt="Logo" />
        </div>
        <div className='menu-toggle' onClick={toggleMenu}>
          {/* Add a simple hamburger icon here */}
          <div className={`bar ${isOpen ? 'open' : ''}`}></div>
          <div className={`bar ${isOpen ? 'open' : ''}`}></div>
          <div className={`bar ${isOpen ? 'open' : ''}`}></div>
        </div>
        <div className={`right ${isOpen ? 'open' : ''}`}>
          <NavLink exact to="/" activeClassName="active-link">Home</NavLink>
          <NavLink exact to="/About" activeClassName="active-link">About</NavLink>
          <NavLink exact to="/Courses" activeClassName="active-link">Courses</NavLink>
          <NavLink exact to="/Contact" activeClassName="active-link">Contact</NavLink>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
